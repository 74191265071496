<template>
  <div>
    <default-navigation />

    <home-header />

    <main id="main">

      <!-- ======= About Us Section ======= -->
      <section
        id="about-us"
        class="about pt-5 section-bg"
      >
        <div
          class="container"
          data-aos="fade-up"
        >

          <div class="col-md-8 col-lg-6 mx-auto">
            <h2 class="display-3 text-center section-title my-5">
              {{ $t('aboutUsSection.title') }}
            </h2>
          </div>

          <div class="row content">
            <div class="col-lg-6">
              <img
                src="@/assets/images/art/modalmu_illustration.svg"
                alt="Modalmu Illustration"
                class="w-100"
              >
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0">
              <h2 class="display-2 about-title modalmu-blue mb-3">
                {{ $t('aboutUsSection.backgroundTitle') }}
              </h2>
              <div class="about-text">
                {{ $t('aboutUsSection.backgroundContent1') }}
              </div>
              <!-- <div class="about-text">
                {{ $t('aboutUsSection.backgroundContent2') }}
              </div>
              <div class="about-text">
                {{ $t('aboutUsSection.backgroundContent3') }}
              </div> -->
            </div>
          </div>

        </div>
      </section><!-- End About Us Section -->

      <!-- ======= Team Section ======= -->

    </main><!-- End #main -->

    <footer-default />

    <a
      href="#"
      class="back-to-top"
    ><i class="ri-arrow-up-line" /></a>
  </div>
</template>

<script>
/* eslint-disable */
import DefaultNavigation from '@/components/navigation/DefaultNavigation.vue'
import HomeHeader from '@/components/header/HomeHeader.vue'
import FooterDefault from '@/components/footer/FooterDefault.vue'

export default {
  metaInfo: {
    title: 'MODALMU',
    titleTemplate: '%s | Bersama Modalmu Kita Bisa',
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Berdiri sejak 2020, (MODALMU) PT.Fintek Modalmu Bersatu adalah Marketing platform di Indonesia, alternatif pendanaan bagi pelaku UMKM & meningkatkan pembangunan ekonomi indonesia.' },
      { name: 'og:title', content: 'MODALMU | Bersama Modalmu Kita Bisa' },
      { name: 'og:description', content: 'Berdiri sejak 2020, (MODALMU) PT.Fintek Modalmu Bersatu adalah Marketing platform di Indonesia, alternatif pendanaan bagi pelaku UMKM & meningkatkan pembangunan ekonomi indonesia.' },
    ],
  },
  components: {
    HomeHeader,
    DefaultNavigation,
    FooterDefault,
  },
  data() {
    return {
      db: {},
    }
  },
  computed: {
  },
  created() {
  },
  mounted() {
    const about = document.getElementById('about-us')
    // console.log(about.offsetTop)
    // window.scrollTo(about.offsetTop, 0)
  },
  methods: {
    async viewProfile(person) {
      await this.$router.push({ name: 'team', params: { personDetail: person, scrollTo: 'profile' } })
    },
    afterLeave() {
      this.$root.$emit('triggerScroll')
    },
  },
}
</script>

<style lang="scss" scope>
</style>
