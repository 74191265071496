<template>
  <div>
    <!-- ======= Hero Section ======= -->
    <div
      id="modalmu-slider"
      class="page-title-wrapper gradient-blue"
    >
      <div class="container">
        <div class="divider-100 pt-5" />
        <div class="indonesia">
          <div class="row md-text-center">
            <div class="col-12">
              <h1
                class="title-display-2 mb-3"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                {{ $t('header.comingSoon') }}
              </h1>
              <h2
                class="display-desc"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                {{ $t('header.comingSoonDesc') }}
              </h2>
              <div
                class="mt-5"
                data-aos="fade-up"
                data-aos-delay="800"
              >
                <a
                  id="btn-soon"
                  class="btn btn-l btn-white btn-strong-hover"
                >
                  <img
                    src="@/assets/images/art/google_play.png"
                    class="d-inline-block mr-2"
                    alt="Play Store"
                    height="20px"
                  >
                  {{ $t('header.btnComingSoon') }}
                </a>
              </div>
              <div
                class="d-none mt-3"
                data-aos="fade-up"
                data-aos-delay="1500"
              >
                <div class="d-inline-block align-middle text-white">
                  <img
                    src="@/assets/images/art/google_play.png"
                    class="d-inline-block mr-2"
                    alt="Play Store"
                    width="25px"
                  >
                  Google Play
                </div>
                <div class="d-none align-middle text-white ml-4">
                  <img
                    src="@/assets/images/art/apple.svg"
                    class="d-inline-block mr-2"
                    alt="App Store"
                    width="25px"
                  >
                  App Store
                </div>
              </div>
            </div>
            <!--/column -->
          </div>
        </div>
        <div class="pointer-none d-none d-lg-block phone-mockup">
          <div class="row justify-content-end text-right px-0 m-0">
            <div
              class="col-lg-6 offset-lg-6 col-xl-4 offset-xl-6 p-0"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <figure class="w-100">
                <img
                  src="@/assets/images/art/hand-phone-2.png"
                  alt="Modalmu App"
                >
              </figure>
            </div>
            <!--/.row -->
          </div>
          <!-- /.container -->
        </div>
        <!--/.row -->
      </div>
      <!-- /.wrapper -->
      <div class="divider divider-200">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="fill-section-bg"
          preserveAspectRatio="none"
          viewBox="0 0 1280 200"
        >
          <path d="M 0 200L 1280 200 L 1280 1 C 1173.84 43.649 907.689 75.826 640.502 75.564 C 374.355 75.304 107.167 42.771 0 0 L 0 200 Z" />
        </svg>
      </div>
    </div>
    <!-- End Hero -->
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style scoped>
  h2.display-desc {
    font-weight: 400;
    font-size: 28px;
  }
</style>
